.projectenlink-main{
    padding: 70px 0;
    background-color: #68B16F;
    text-align: center;
}
.projectenlink-main h3{
    color: #fff;
    font-size: 48px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.projectenlink-main a,.projectenlink-main span{
    color: #000;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    text-decoration: none;
}
@media screen and (max-width:767px) {
    .projectenlink-main h3{
        font-size: 30px;
    }
    .projectenlink-main a,.projectenlink-main span{
        font-size: 18px;
    }
}