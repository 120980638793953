.kozijnen-title .projectenpara-main p{
    color: #fff;
    font-size: 30px;
    max-width: 800px;
    margin: 0 auto;
} 
.kozijnen-specialist{
    max-width: 750px;
    margin: 50px auto 50px;
}
.kozijnen-specialist h4{
    color: #000;
    font-size: 42px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin: 0 0 20px 0;
}
.kozijnen-specialist h4 span {
    position: relative;
}
.kozijnen-specialist h4 span::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: -6px;
    border: 2px solid #09FF23;
    width: 40px;
}
.kozijnen-ontdek,.kozijen-maincards{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.kozijnen-ontdek-para{
    width: 45%;
    margin: auto 0;
}
.kozijnen-ontdek-image img{
    max-width: 450px;
}
.kozijen-waaroom-keizen{
    margin: 50px 0;
}
.contact-anchor{
    margin: 20px 0 40px 0;
}
.contact-anchor a{
    color: #fff;
    font-size: 24px;
    text-align: center;
    text-decoration: none;
    padding: 10px 160px;
    border-radius: 10px;
    background-color: rgba(103, 177, 111, 1);
}
.kozijen-waaroom-keizen h3{
    font-size: 48px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.kozijen-waaroom-keizen p{
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    max-width: 650px;
}
.kozijnen-ontdek-para p{
   color: rgba(0, 0, 0, 1);
   font-family: 'Poppins', sans-serif;
   letter-spacing: 2%;
   font-size: 33px;
}
.kozijnen-card1,.kozijnen-card3{
    width: 53%;
    margin: 0 0 25px 0;
}
.kozijnen-card2,.kozijnen-card4{
    width: 44%;
    margin: 0 0 25px 0;
   
}
@media screen and (max-width:991px){
    .kozijnen-ontdek-para{
        width: 95%;
    }
    .kozijnen-ontdek-image {
        margin: 0 auto;
    }
    .kozijnen-ontdek-para p{
        font-size: 24px;
     }
     .kozijen-waaroom-keizen h3 {
        font-size: 36px;
    }
    .kozijen-waaroom-keizen p{
        font-size: 20px;
    }
    .kozijnen-card1,.kozijnen-card3, .kozijnen-card2,.kozijnen-card4{
        width: 95%;
        margin: 0 auto 25px auto;
    }
}
@media screen and (max-width:767px){
    .contact-anchor a{
        font-size: 20px; 
        padding: 10px 100px;
    }
    .kozijnen-specialist h4{
        font-size: 28px;
    }
    .kozijnen-specialist h4 span::after{
        bottom: 0px;
    }
}