.platte-main{
    text-align: center;
    background-color: #68A66E;
    border-radius: 20px;
    padding: 15px 7px 30px 7px;
}
.platte-image img{
    max-width: 80px;
}
.platte-main h3{
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 800;
    padding: 8px 0;
}
.platte-main p{
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
}
@media screen and (max-width:1166px) {
    .platte-main h3{
        font-size: 18px;
    }
    .platte-main p{
        font-size: 16px;
    }
}