.contact-mian{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 50px 0 70px 0;
}
.contact-sec1{
    width: 67%;
}
.contact-sec2{
    width: 30%;
    background-color: #55885A;
    padding: 40px 25px;
    border-radius: 25px;
    margin: auto 0;
}
.contact-sec1-data{
    background-color: #55885A;
    padding: 20px 15px;
    border-radius: 25px;
}
.contact-sec1 h4{
    color: #000;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin: 0 0 25px 0;
}
.contact-sec1-data p{
    color: #fff;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    max-width: 640px;
}
.contact-sec1-image{
    width: 90%;
    margin: 20px 0 0px auto;
}
.contact-sec2 h3{
    color: #fff;
    font-size: 32px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.contact-sec2 h6{
    color: #353535;
    opacity: 98%;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    padding:25px 0 0 0;
}
.contact-sec2 a,.contact-sec2 p{
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.contact-sec2 p{
    padding: 50px 0 0 0;
}
@media screen and (max-width:1166px) {
    .contact-sec2 h3 {
        font-size: 26px;
    }
}
@media screen and (max-width:991px) {
    .contact-sec1{
        width: 95%;
        margin: 0 auto 25px;
    }
    .contact-sec2{
        width:65%;
        margin: 0 auto;
        text-align: center;
    }
}
@media screen and (max-width:767px) {
    .contact-sec2{
        width:95%;
    }
    .contact-mian{
        margin: 30px 0 40px 0;
    }
    .contact-sec2 p{
        padding: 25px 0 0 0;
    }
    .contact-sec1 h4{
        font-size: 32px;
    }
    .contact-sec1-data p{
        font-size: 18px;
    }
}

