.Projecten-main{
    box-shadow: 1px 27px 35px -4px rgba(0, 0, 0, 0.25);
}
.Projecten-image img{
    margin-bottom: -4px;
}
.Projecten-main a{
    text-decoration: none;
}
.Projecten-main a h3{
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    color: #000;
    background-color: #68A66E;
    padding: 12px 0;
    margin: 0 0 0 0 !important;
    border-radius:0 0 8px 8px;
}