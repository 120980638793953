.ismedia-main{
    background-color: #55885A;
    padding: 30px 0;
    display: flex;
    justify-content: space-around;
}
.ismedia-logo img{
    max-width: 411px;
}
.ismedia-icons{
    display: flex;
    margin: auto  0;
}
.ismedia-icons h3{
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 20px;
    margin:auto 0;
}
.ismedia-icons a img{
    max-width: 60px;
    margin-left: 15px;
}
@media screen and (max-width:991px) {
    .ismedia-logo img{
        max-width: 350px;
    }
    .ismedia-icons a img{
        max-width: 50px;
    }
}
@media screen and (max-width:767px) {
    .ismedia-main{
      flex-direction: column;
    }
    .ismedia-logo,.ismedia-icons{
      margin: 0 auto;
    }
    .ismedia-icons a img{
        max-width: 40px;
        margin-left: 8px;
    }
}