.overons-sec2{
    margin: 30px 0;
}
.overons-head{
    background: url("../assets/Images/overons-bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 55vh;
}
.overons-head p{
   color: #fff;
   font-family: 'Inter', sans-serif;
   font-weight: 700;
   font-size: 54px;
  padding-top: 150px;
}
@media screen and (max-width:1166px) {
    .overons-head{
        height: 45vh;
    }
    .overons-head p{
       padding-top: 100px;
    }
    .overons-head p{
        font-size: 40px;
    }
}
@media screen and (max-width:767px) {
    .overons-head{
        height: 50vh;
        background-size: 100% 100%;
    }
    .overons-head p{
       padding-top: 130px;
     }
     .overons-head p{
        font-size: 30px;
    }
    
}