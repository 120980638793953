.projectimage-main{
    margin: 80px 0;
}
.projectimagesec1,.projectsec1-content1,.projectenImagesec1{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;
}
.projectImagesec-main .projectimagesec1{
    margin: 30px 0;
}
.projectimagesec1{
    margin: 0 0 25px 0;
}
.projectsec1-content1{
    width: 64%;
}
.projectsec1-content2{
    width: 30%;
}
.project-sec1-contnet1-data-sub1 ,.project-sec1-contnet1-data-sub2{
    width: 48%;
}
.projectsec1-content1{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.project-sec1-content1-dataP{
    min-width: 500px;
}
.project-sec1-content1-data-sub1{
    width: 30%;
}
.project-sec1-content1-data-sub2{
    width: 30%;
}
.image-border img{
    border-radius: 50px;
}


.image-container {
    position: relative;
    /* display: inline-block; */
}
.middle{
    transition: .5s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: fit-content;
    width: fit-content;
    text-align: center;
    transition: opacity 0.3s ease-in-out;
}
.middle p{
    background-color: rgba(58, 169, 89, 1);
    padding: 15px 8px;
    border-radius: 15px;
    font-weight: bold;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.image-container:hover img{
    opacity: 0.7;
}
.image-container:hover .middle{
    opacity: 1;
}

@media screen and (max-width:767px){
    .projectsec1-content1,.project-sec1-content1-data-sub1,.projectsec1-content2,.project-sec1-contnet1-data-sub1,
    .project-sec1-contnet1-data-sub2{
        width: 100%;
    }
    .projectImagesec-main a img{
        margin-bottom: 15px;
    }
}
