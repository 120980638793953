.warmtepompen-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Poppins", sans-serif;
    background-color: #67b16f;
    padding: 7%;
    color: white;
    height: 530px;
  }
  
  .warmtepompen-banner-content {
    width: 50%;
  }
  .warmtepompen-banner-image {
    width: 50%;
  }
  
  .warmtepompen-banner-content h1 {
    font-size: 44px;
    font-weight: 700;
  }
  .warmtepompen-banner-content p {
    font-size: 24px;
  }
  
  .warmtepompen-banner-image img{
    width: 100%;
    height: 100%;
    max-width: 600px;
  }
  
  .warmtepompen-main-heading {
    margin-top: 70px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .warmtepompen-main-heading-content {
    width: 50%;
    text-align: center;
  }
  
  .warmtepompen-main-heading-content h1 {
    font-weight: 700;
    font-size: 40px;
  }
  .warmtepompen-main-heading-content p {
    font-size: 30px;
    font-weight: 400;
  }
  
  .warmtepompen-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 7%;
    padding-top: 4%;
    font-family: "Poppins", sans-serif;
  }
  .warmtepompen-section-content {
    width: 22%;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 3%;
  }
  
  .warmtepompen-section-content img {
    width: 100%;
    height: 250px;
    border-top-left-radius: 3%;
    border-top-right-radius: 3%;
  }
  
  .warmtepompen-section-content h5 {
    margin: 0px;
    padding: 6px 0px 6px 6px;
    font-weight: 700;
    font-size: 18px;
  }
  .warmtepompen-section-content p {
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 18px;
    padding: 6px 0px 8px 6px;
  }
  
  .warmtepompen-professional {
    display: flex;
    flex-direction: row;
    padding: 7%;
    padding-top: 1%;
    justify-content: space-evenly;
    align-items: center;
    font-family: "Poppins", sans-serif;
  }
  .warmtepompen-professional-image {
    width: 50%;
    padding: 2%;
  }
  
  .warmtepompen-professional-content {
    padding: 3%;
    width: 50%;
  }
  
  .warmtepompen-professional-content-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: "Poppins", sans-serif;
    padding: 2%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .warmtepompen-professional-content h1 {
    font-weight: 650;
    font-size: 35px;
  }
  .warmtepompen-professional-content p {
    font-size: 14px;
    margin-bottom: 40px;
  }
  
  .warmtepompen-professional-content-section-image {
    width: 200px;
  }
  .warmtepompen-professional-content-section-image img {
    width: 100px;
    height: 90px;
  }
  
  .warmtepompen-professional-content-section-content {
    padding: 10px;
  }
  .warmtepompen-professional-content-section-content h5 {
    font-size: 22px;
    font-weight: 600;
  }
  
  .warmtepompen-professional-content-section-content p {
    font-size: 18px;
    font-weight: 400;
  }
  
  .warmtepompen-seperator {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  @media screen and (max-width: 768px) {
    .warmtepompen-banner {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content;
    }
  
    .warmtepompen-banner-content {
      width: 100%;
    }
    .warmtepompen-banner-image {
      width: 100%;
    }
    .warmtepompen-banner-image img {
      height: 450px;
    }
  
    .warmtepompen-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .warmtepompen-section-content {
      width: 47%;
      margin-top: 30px;
    }
    .warmtepompen-professional {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 5%;
    }
    .warmtepompen-professional-image {
      width: 100%;
    }
  
    .warmtepompen-professional-image img {
      width: 100%;
      height: 400px;
    }
    .warmtepompen-professional-content {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 475px) {
    .warmtepompen-banner-content h1 {
      font-size: 31px;
    }
    .warmtepompen-banner-content p {
      font-size: 19px;
    }
  }
  
  @media screen and (max-width: 500px) {
    .warmtepompen-professional-content-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .warmtepompen-professional-content-section-image {
      padding: 3%;
    }
  }
  @media screen and (max-width: 640px) {
    .warmtepompen-main-heading-content h1 {
      font-size: 32px;
    }
    .warmtepompen-main-heading-content p {
      font-size: 25px;
    }
  }
  
  @media screen and (max-width: 425px) {
    .warmtepompen-main-heading-content {
      width: 80%;
    }
    .warmtepompen-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .warmtepompen-section-content {
      width: 100%;
      margin-top: 30px;
    }
  }
  