.btnspan-main span{
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #000000;
    background-color: #67B16F;
    border: 2px solid #67B16F;
    cursor: pointer;
    text-decoration: none;
    padding: 8px 20px;
    border-radius: 8px;
}
.btnspan-main span:hover{
    background-color: #fff;
}