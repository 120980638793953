.plattelist-main{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 40px 0;
}
.plattelist-content{
    width: 23%;
}
.platte-mar{
    margin-top: 40px;
}
@media screen and (max-width:991px) {
    .plattelist-content{
        width: 45%;
    }
}
@media screen and (max-width:767px) {
    .plattelist-content{
        width: 90%;
        margin: 0 auto 30px auto;
    }
    .platte-mar{
        margin-top: unset;
    }

}