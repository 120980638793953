.powerHeader{
    /* background: url("../assets/Images/mainhead.png"); */
    /* background: url("../assets/Images/head-gif.gif"); */
    background: url("../assets/Images/mainhead1.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    position: relative;
}
.powerheader-people1{
    position: absolute;
    bottom: 8%;
    left: 5%;
}
.powerheader-people2{
    position: absolute;
    bottom: 8%;
    right: 5%;
}
.powervideo-logo{
    max-width: 80px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: fit-content;
    width: fit-content;
}
.power-downhat{
    max-width: 40px;
    position: absolute;
    bottom: 1%;
    right: 0;
    left: 0;
    margin: auto;
}
.power-begin h3{
    color: rgba(58, 169, 89, 1);
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-align: center;
    margin: 15px 0 0 0;
}
.power-sec,.power-sec-content1{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px 0;
}
.power-sec-content1{
    width: 45%;
}
.power-sec-content2{
    width: 30%;
    text-align: center;
    margin: auto;
}
.power-sec-content-image{
    width: 47%;
}
.power-sec-content-image img{
    min-height: 400px;
}
.power-sec-content2 h4{
    color: #000000;
    font-size: 36px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.power-sec-content2 p{
    color: #000000;
    opacity: 87%;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.power-sec-content2 h5{
    color: #39CA47;
    opacity: 87%;
    font-size: 36px;
    margin: 15px 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
@media screen and (max-width:1166px) {
    .power-sec-content1{
        width: 53%;
    }
    .power-sec-content2{
        width: 37%;  
    }
    .power-sec-content-image img{
        min-height: 350px;
    }  
}
@media screen and (max-width:991px) {
    .power-sec-content1{
        width: 55%;
    }
    .power-sec-content-image img{
        min-height: 320px;
    }  
    .power-sec-content2 h5,.power-sec-content2 h4{
        font-size: 28px;
    }
    .power-sec-content2 p{
        font-size: 24px;
    }
}
@media screen and (max-width:767px) {
    .power-sec-content1{
        width: 90%;
        margin: 0 auto;
    }
    .power-sec-content2{
        width: 90%;
        margin: 20px auto;  
    }
    .power-sec-content-image{
        width: 90%;
        margin: 0 auto 25px auto;
    }
}