.headlogo-image img {
  max-width: 220px;
}
.mainheader-bg {
  background: #56885b;
}
.mainhead-linkdropdown .nav-link {
  padding: 0 0 0 0 !important;
  margin: -4px 0 0 0;
}
.mainhead-button a {
  font-size: 18px;
  color: #fff;
  padding: 8px 25px;
  font-weight: 400;
  background-color: #000;
  border-radius: 25px;
  font-family: "Inter", sans-serif;
  text-decoration: none;
}
.mainhead-navigators {
  display: flex;
}
.mainhead-link1 a,
#navbarScrollingDropdown {
  color: #000;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 22px;
  padding: 6px 18px;
  border-radius: 50px;
}
.mainhead-link1 a:hover,
#navbarScrollingDropdown:hover {
  background-color: #fff !important;
  color: #000 !important;
}
#navbarScrollingDropdown {
  padding: 4px 18px !important;
}
.mainhead-link1 {
  margin: 0px 30px 0 0;
}
.navbar-nav {
  padding: 0 0 0 0 !important ;
}
.dropdown-menu {
  /* background-color: rgba(255, 255, 255, 0.44) !important; */
  background-color: rgb(0, 0, 0, 1) !important;
}
.mainhead-item ul {
  margin-bottom: 0 !important;
}
.mainhead-item ul li {
  color: #fff;
}
.mainhead-item ul li a {
  color: #fff;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 22px;
  border-bottom: 1px solid rgb(125, 123, 123);
}
.mainhead-item ul li:hover {
  color: #000;
}
@media screen and (max-width: 1166px) {
  .mainhead-link1 {
    margin: 0px 18px 0 0;
  }
  .mainhead-link1 a,
  #navbarScrollingDropdown {
    font-size: 21px;
  }
}
@media screen and (max-width: 991px) {
  .collapse-abs {
    position: absolute;
    top: 105px;
    right: 0;
    left: 0;
    height: 67vh;
    width: 100%;
    background: rgba(120, 175, 125, 0.84);
    transition-timing-function: ease-in-out;
  }
  .mainhead-navigators {
    display: unset;
  }
  .mainhead-button {
    margin: 0 0 0 10px;
  }
}
