.powerheadcard-image img{
    max-width: 280px;
    border: 10px solid rgba(255, 255, 255, 0.46);
}
.powerheadcard-image{
    text-align: center;
    margin: 0 0 15px 0;
 }
.powerheadcard-para{
    background-color: rgba(58, 169, 89, 1);
    border-radius: 50px;
}
.powerheadcard-para p{
    max-width: 180px;
    text-align: center;
    padding: 0 0 0 0 !important;
    margin: 0 auto;
}
.powerheadcard-para a{
    color: #000000;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-decoration: none;
}
@media screen and (max-width:767px) {
    .powerheadcard-image img{
        max-width: 120px;
        margin: 0 auto;
    }
    .powerheadcard-para a{
        font-size: 16px
    }  
    .powerheadcard-para p{
        max-width: 150px;
    }  
}