.footer-main{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 70px 0 30px 0;
}
.sec1-width{
    width: 30%;
}
.sec2-width{
    width: 22%;
}
.sec3-width{
    width: 16%;
}
.footer-sec1 h3{
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #000000;
    margin-bottom: 15px;
}
.footer-sec1 a{
    text-decoration: none;
    color: #000000;
}
.footer-location span,.footer-tel span{
    font-size: 18px;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.footer-tel span p a{
    color: #000000;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.footer-tel{
   max-width: 300px;
   margin-top: 15px;
}
.footer-list ul, .footer-list1 ul{
    padding: 0px 0 0 20px;
}
.footer-list ul li{
    font-size: 18px;
    color: #0F0000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 3px 0;
    text-decoration: underline;
}
.footer-list1 ul li a{
    font-size: 18px;
    color: #070000;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    padding: 3px 0;
    text-decoration: underline;
}
.footer-location,.footer-tel{
    display: flex;
}
.footer-tel span p{
    padding: 5px 0;
    margin: 0 0 0 0 !important;
}
.footer-location svg,.footer-tel svg{
    font-size: 35px;
}
@media screen and (max-width:1166px) {
    .sec2-width{
        width: 26%;
    }
    .sec3-width{
        width: 20%;
    }
}
@media screen and (max-width:991px) {
    .sec1-width{
        width: 35%;
    }
    .sec2-width{
        width: 34%;
    }
    .sec3-width{
        width: 25%;
    }
    .footer-sec1 h3 {
        font-size: 24px;
    }
}
@media screen and (max-width:767px) {
    .sec1-width,.sec2-width,.sec3-width{
        width: 100%;
    }
}
