.privacypolicy-main{
    padding: 40px 0;
}
.privacypolicy-main h3{
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.privacypolicy-main h6{
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.privacypolicy-main h4{
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.privacypolicy-main p{
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
@media screen and (max-width:767px) {
    .privacypolicy-main h3{
        font-size: 22px; 
    }
    .privacypolicy-main h6{
        font-size: 18px;
    }
    .privacypolicy-main h4{
        font-size: 18px;
    }
    .privacypolicy-main p{
        font-size: 16px;
    }
    
}