.ceo-main{
    padding: 50px 0;
    text-align: center;
}
.ceo-main .ceo-logo img{
    max-width: 500px;
    border-radius: 50%;
}
.ceo-main .ceo-content p{
    font-size: 20px;
    color: #000;
    margin: 0 auto;
    max-width: 1050px;
    padding: 0 10px 15px 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}
@media screen and (max-width:767px) {
    .ceo-main .ceo-content p{
        font-size: 18px;
    }
}