.staatpara-main{
    background-color: #55885A;
}
.staatpara-sec{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.staatpara1,.staatpara2{
    width: 35%;
    padding: 50px 0;
}
.staatpara1 p,.staatpara2 p{
    color: #fff; 
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}
@media screen and (max-width:1024px) {
    .staatpara1,.staatpara2{
        width: 45%;
    }
}
@media screen and (max-width:767px) {
    .staatpara1,.staatpara2{
        width: 100%;
        padding: 30px 0 10px 0;
    }
}