.ervaren-headpara{
    width: 38%;
    margin: 70px auto 50px;
}
.ervaren-logo{
    position: relative;
}
.ervaren-logo-content{
   position: absolute;
   bottom: 50px;
   left: 0;
   right: 0;
} 
.ervaren-logo-content1 a{
    text-decoration: none;
}
.ervaren-logo-content1 a p{
    color: #000;
}
.ervaren-logo-content p,.ervaren-logo-content1 a p{
    font-weight: 500;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    background: linear-gradient(257.57deg, rgba(103, 177, 111, 0) -51.45%, rgba(103, 177, 111, 0) -50.35%, rgba(55, 84, 58, 0.421513) 1.41%, rgba(8, 188, 27, 0.504538) 104.14%, rgba(55, 125, 63, 0.76) 125.96%);
    border: 2px solid #140817;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
    padding: 10px 35px;
}
@media screen and (max-width:1166px) {
    .ervaren-headpara {
        width: 44%;
    }
}
@media screen and (max-width:1024px) {
    .ervaren-headpara {
        width: 52%;
    }
}
@media screen and (max-width:991px) {
    .ervaren-headpara {
        width: 68%;
    }
}
@media screen and (max-width:767px) {
    .ervaren-headpara {
        width: 95%;
        margin: 40px auto 30px;
    }
    .ervaren-logo-content{
        position: unset;
        margin: 15px 0;
     } 
     .ervaren-logo-content p{
        font-size: 18px;
        padding: 10px 15px;
    }
    .ervaren-head h3{
        font-size: 28px;
    }
    .ervaren-headpara h3{
        font-size: 24px;
    }
    .ervaren-headpara p{
        font-size: 20px;
    }
}
