.workcard-main{
    position: relative;
}
.worker-number{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: fit-content;
    width: fit-content;
    margin: auto;
}
.worker-number h4{
    color: #EAEAEA;
    text-align: center;
    opacity: 69%;
    font-size: 36px;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
}
.worker-number p{
    color: #EAEAEA;
    text-align: center;
    opacity: 69%;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
}
@media screen and (max-width:991px) {
    .worker-number h4{
        font-size: 30px;
    }  
    .worker-number p{
        font-size: 20px;
    }
}