.projectenpara-main{
    padding:70px 0;
    background-color: #68B16F;
    text-align: center;
}
.projectenpara-main h3{
    color: #fff;
    font-size: 48px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.projectenpara-main p{
    color: #000;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}