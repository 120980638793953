.kracht-sec,.kracht-number-sec{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.kracht-main{
    margin: 50px 0;
}
.kracht-content1,.kracht-content2{
    width: 45%;
}
.kracht-content1{
    display: flex;
    justify-content: space-between;
    margin: auto 0;
}
.kracht-number1{
    text-align: center; 
    width: 50%;
}
.kracht-number1 h4,.kracht-content2 h3{
    font-size: 36px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #000000;
    margin: 0 0 0 0;
}
.kracht-number1 p{
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000000;
    margin: 0 0 0 0;
}
.kracht-content2-image{
   margin: 10px 0;
}
.kracht-content2-image img{
    max-width: 210px;
}
.kracht-content2-para p{
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    color: #000000;
    margin: 30px 0 20px 0;
}
@media screen and (max-width:1166px) {
    .kracht-content2-para p{
        font-size: 18px;
        margin: 20px 0 15px 0;
    }
    .kracht-number1 h4,.kracht-content2 h3{
        font-size: 32px;
    }
    .kracht-content1,.kracht-content2{
        width: 47%;
    }
    .kracht-content2-image{
        margin: 5px 0;
     }
     .kracht-number1 p{
        font-size: 18px;
    }
}
@media screen and (max-width:991px) {
    .kracht-content1,.kracht-content2{
        width: 95%;
        margin: 0 auto;
    }
    .kracht-number1{
        width: 35%;
    }
}
@media screen and (max-width:767px) {
    .kracht-number1{
        width: 95%;
        margin: 0 auto 30px auto;
    }
    .kracht-main{
        margin: 30px 0;
    }
}