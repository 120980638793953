.architect-sec1{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 40px 0;
}
.architect-sec1-content1{
    width: 42%;
    margin: 0px 0 50px 0;
}
.architect-sec1-content2{
    width: 45%;
    margin: 0px 0 50px 0;
}
.architect-anc{
    margin: 15px 0;
}
.architect-anc a{
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #000;
    text-decoration: none;
    padding: 11px 25px;
    border-radius: 35px;
}
@media screen and (max-width:1166px){
    .architect-sec1-content1{
        width: 47%;
    }
    .architect-sec1-content1 p{
        font-size: 20px;
    }
}
@media screen and (max-width:991px){
    .architect-sec1-content1{
        width: 100%;
    }
    .architect-sec1-content2{
        width: 80%;
        margin: 0 auto 50px auto;
    } 
}
@media screen and (max-width:767px){
    .architect-sec1-content1 h3{
        font-size: 28px;
    }
    .architect-sec1-content1 h4{
        font-size: 18px;
    }
    .architect-head h3{
        font-size: 36px;
    }
}