.projectendetail-main{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 50px 0;
}
.projectendetail-images{
    width: 24%;
}
.projectendetail-images img{
    margin: 0 0 30px 0;
    max-height: 220px;
}
.projectendetail-para p{
    font-size: 20px;
    color: #000;
    margin: 40px auto 0;
    max-width: 1050px;
    padding: 0 10px 15px 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}
@media screen and (max-width:1166px) {
    .projectendetail-images{
        width: 31%;
    }
    .projectendetail-images img{
        max-height: 200px;
    }
}
@media screen and (max-width:991px) {
    .projectendetail-images{
        width: 48%;
    }
}
@media screen and (max-width:767px) {
    .projectendetail-images{
        width: 95%;
        margin: 0 auto 20px;
    }
}