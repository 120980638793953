.headpara-mian h3{
    position: relative;
    color: #000;
    font-size: 36px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin: 0 0 20px 0;
}
.headpara-mian h3::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    border: 2px solid #09FF23;
    width: 40px;
}
.headpara-mian p{
    color: #000;
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    margin: 0 0 0 0;
    padding: 10px 0;
    letter-spacing: -0.5px;
}
.headpara-mian h4{
    color: #000;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}