.kozijenwaaromcard-main{
    border: 1px solid rgba(9, 255, 35, 1);
    border-radius: 5px;
    padding: 20px 10px;
    min-height: 200px;
}
.kozijenwaaromcard-main h4{
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
/* .kozijenwaaromcard-main p{

} */
.kozijen-btns{
    display: flex;
}
.kozijen-waroom-btn1{
    margin: 0 15px 0 0;
}
.kozijen-waroom-btn1 .btncomp-main a, .kozijen-waroom-btn2 .btncomp-main a{
    background-color: rgba(103, 177, 111, 0.63);
}
@media  screen and(max-width:1166px) {
    .kozijen-waroom-btn1 a, .kozijen-waroom-btn2 a{
        font-size: 18px;
        padding: 8px 12px;
    }
}
@media  screen and (max-width:767px) {
    .kozijenwaaromcard-main{
        min-height: unset;
    }
    .kozijen-btns{
        display: unset;
        flex-direction: column;
        text-align: center;
    }
    .kozijen-waroom-btn1 .btncomp-main a, .kozijen-waroom-btn2 .btncomp-main a{
        font-size: 16px;
        padding: 8px 10px;
    }
    .kozijen-waroom-btn1,.kozijen-waroom-btn2{
        margin: 0 0 30px 0;
    }
}