.projectenlist-main{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.projectenlist-sec{
    width: 31%;
}
@media screen and (max-width:991px) {
    .projectenlist-sec{
        width: 45%;
        margin-bottom: 30px;
    } 
}
@media screen and (max-width:767px) {
    .projectenlist-sec{
        width: 90%;
        margin: 0 auto 30px auto; 
    } 
}