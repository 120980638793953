.algemene-main{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px 0 50px 0;
}
.algemene-content{
    width: 47%;
    margin: auto 0;
}
.algemene-image{
    width: 30%;
}
.algemene-content h4{
    color: #000;
    font-size: 36px;
    margin: 0 0 15px 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.algemene-content p{
    color: #000;
    font-size: 20px;
    margin: 0 0 0 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    max-width: 440px;
}
@media screen and (max-width:991px) {
    .algemene-image{
        width: 42%;
    }
    .algemene-content{
        width: 53%;
    }
    .algemene-content p{
        font-size: 18px;
    }
    
}
@media screen and (max-width:767px) {
    .algemene-image,.algemene-content{
        width: 95%;
        margin: 0 auto 20px;
    } 
    .algemene-content h4{
        font-size: 28px;
    }
}