.powerproject-images1,.powerproject-images2,.another-sec{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.another-sec{
    width: 66%;
}
.powerproject-main{
    background-color:  #78AF7D;
    padding: 40px 0;
}
.powerImages-main{
    width: 95%;
    margin: 0 auto;
}
.image-border img{
    border-radius: 50px;
}
.powerproject-main h4{
    text-align: center;
    color: #000000;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.powerproject-image-sec1{
    width: 62%;

}
.powerproject-image-sec2{
    width: 34%;
}
.powerproject-image-sec1,.powerproject-image-sec2{
   padding: 15px;
}
.image-sec2-image1{
    width: 32%;
}
.sec-image{
    width: 48%;
}
.powerproject-downarrow img{
    max-width: 35px;
}


.image-container {
    position: relative;
    /* display: inline-block; */
}
.middle{
    transition: .5s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: fit-content;
    width: fit-content;
    text-align: center;
    transition: opacity 0.3s ease-in-out;
}
.middle p{
    background-color: rgba(58, 169, 89, 1);
    padding: 15px 8px;
    border-radius: 15px;
    font-weight: bold;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.image-container:hover img{
    opacity: 0.7;
}
.image-container:hover .middle{
    opacity: 1;
}




@media screen and (max-width:767px){
    .powerproject-images1,.powerproject-images2{
        flex-direction: column-reverse;
    }
    .image-sec2-image1,.sec-image,.another-sec,.powerproject-image-sec1,.powerproject-image-sec2{
        width: 100%;
        margin-bottom: 25px;
    }
}