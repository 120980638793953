.basiscard-main{
    background-color: #68B16F;
    border-radius: 10px;
    text-align: center;
    padding: 18px 0;
}
.basiscard-image img{
    max-width: 120px;
}
.basiscard-main p{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    padding:10px 5px;
    margin: 0 0 0 0 !important;
}
.basiscard-main a{
    text-decoration: none;
}