.basisvan-head {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                      url("../assets/Images/basis-bg.png");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 55vh;
  }
.basisvan-head p{
    color: #070000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    opacity: 55%;
    font-size: 30px;
    padding: 130px 0 0px 0;
    margin: 0 0 0 0;
}
.basisvan-head span{
   color: #fff;
   font-family: 'Inter', sans-serif;
   font-weight: 700;
   font-size: 54px;
   margin: 0 0 0 0;
}
@media screen and (max-width:1166px) {
    .basisvan-head{
        height: 45vh;
    }
    .basisvan-head p{
       padding-top: 80px;
     }
     .basisvan-head span{
        font-size: 40px;
     }
}
@media screen and (max-width:767px) {
    .basisvan-head{
        height: 50vh;
        background-size: 100% 100%;
    }
    .basisvan-head p{
       padding-top: 130px;
     }
     .basisvan-head span{
        font-size: 24px;
     }
    
}