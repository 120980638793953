.projecon-headpara{
    margin: 50px 0 0 0;
}
.projecon-builder{
    max-width: 500px;
    margin: 0 auto;
    padding: 60px 0 0 0;
    border-top: 1px solid #000000;
}
.projecon-builder-main{
    position: relative;
}
.builder-btn1{
    position: absolute;
    left:0; 
    top: 55%;
}
.builder-btn2{
    position: absolute;
    right: 5%;
    top: 55%;
}
.btn-done{
    width: 10%;
    text-align: center;
}
.listbutton-content1-button{
    display: flex;
    justify-content: space-between;
    margin: 15px 0 25px 0;
}
.listbutton-content1 p{
    font-size: 32px;
    font-family: 'Inter', sans-serif;
}
.builder-btn1 a{
    text-decoration: none;
}
@media screen and (max-width:1166px) {
    .builder-btn1{
        left:-3%; 
    }
    .builder-btn2{
        right: 2%;
    }
}
@media screen and (max-width:991px) {
    .builder-btn1,.builder-btn2{
        position: unset; 
        margin: 30px auto;
        width: 60%;
        text-align: center;
    }
    .projecon-builder{
        max-width: 400px;
    }
}
@media screen and (max-width:767px) {
    .builder-btn1,.builder-btn2{
        width: 95%;
    }
    .builder-btn1 a,.builder-btn2 a{
        font-size: 16px;
        padding: 8px 10px;
    }
    .projecon-head h3{
        font-size: 30px;
    }
    .projecon-headpara h3{
        font-size: 28px;
    }
    .projecon-headpara p{
        font-size: 20px;
    }
    .listbutton-content1 p{
        font-size: 20px;
    }
    .listbutton-content1-button .btnspan-main span {
        font-size: 17px;
    }
}