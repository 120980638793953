.contact-us-content {
    background-color: #67b16f;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: white;
    padding: 1%;
  }
  
  .contact-us-content h1 {
    font-size: 50px;
    font-weight: 700;
    margin-top: 70px;
  }
  .contact-us-content p {
    font-size: 26px;
    margin-bottom: 70px;
    line-height: 24px;
  }
  
  @media (max-width: 320px) {
    .contact-us-content h1 {
      font-size: 40px;
    }
  
    .contact-us-content p {
      font-size: 22px;
    }
  }
  
  .contact-us-input-fields {
    width: 55%;
  }
  
  .contact-us-logo {
    width: 40%;
  }
  .contact-us-input-logo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 70px;
    margin-bottom: 70px;
  }
  
  .contact-us-input-logo textarea {
    background: rgba(103, 177, 111, 0.92);
    box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-weight: 400;
    resize: none;
    border: none;
  }
  
  .contact-us-input-logo textarea::placeholder {
    color: #353535;
  }
  .contact-us-input-logo textarea:focus {
    outline: none;
  }
  
  .contact-us-input-email-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .contact-us-input-email-fields textarea {
    width: 47%;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 1.1%;
    padding-bottom: 0%;
  }
  .contact-us-message-field textarea {
    width: 100%;
    padding-top: 1.3%;
    padding-bottom: 0.8%;
    white-space: wrap;
    margin-top: 4%;
  }
  
  .contact-us-send-button {
    float: right;
    box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.25);
  }
  
  .contact-us-input-fields button {
    background-color: rgba(103, 177, 111, 0.92);
    width: auto;
    border: none;
    font-size: 21px;
    color: #353535;
    border-radius: 0px;
    font-weight: 600;
    margin-top: 3%;
  }
  
  .contact-us-input-fields button:focus {
    outline: none;
  }
  .contact-us-input-fields button:hover {
    background-color: rgba(65, 126, 71, 0.92);
    color: black;
  }
  .contact-us-send-button svg:hover {
    color: black;
  }
  .contact-us-send-button svg {
    height: 2%;
    margin-left: 3px;
    width: 10%;
    color: #353535;
    margin-bottom: 4px;
    width: 12%;
  }
  .contact-us-message-field textarea::-webkit-scrollbar {
    width: 8px; 
    height: 1px;
  }
  .contact-us-message-field textarea::-webkit-scrollbar-thumb {
    background-color: rgb(0, 49, 4); 
    border-radius: 10px;
    border: 2px solid transparent; 
    background-clip: content-box;
  }
  
  .contact-us-logo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .contact-us-logo img {
    margin: auto;
  }
  
  @media (max-width: 768px) {
    .contact-us-input-fields,
    .contact-us-logo {
      width: 100%;
    }
    .contact-us-input-email-fields textarea {
      padding-top: 1.5%;
      padding-bottom: 0%;
    }
  }
  
  @media (max-width: 320px) {
    .contact-us-input-fields,
    .contact-us-logo {
      width: 100%;
    }
    .contact-us-input-email-fields textarea {
      padding-top: 2.2%;
      padding-bottom: 0%;
    }
  }
  
  .contact-us-main {
    position: relative;
  }
  .contact-us-main .contact-us-banner-main {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 65%;
    height: fit-content;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 45px 0;
  }
  .contact-us-banner-main .contact-us-banner-image {
    text-align: center;
  }
  .contact-us-banner-main .contact-us-banner-image img {
    max-width: 300px;
    background-color: #67b16f;
    border-radius: 15px;
  }
  .contact-us-banner-main .contact-us-banner-content p {
    font-size: 20px;
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin: 0 0 0 0 !important;
  }
  @media screen and (max-width: 767px) {
    .contact-us-main .contact-us-banner-main {
      width: 85%;
    }
    .contact-us-main .contact-us-image img {
      min-height: 400px;
    }
    .contact-us-banner-main .contact-us-banner-image img {
      max-width: 240px;
    }
    .contact-us-input-email-fields textarea{
        width: 100%;
        margin: 0 0 15px 0;
    }
  }
  