.basiscardlist-sec{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.basiscardlist-content1,.basiscardlist-content2,.basiscardlist-content3{
    width: 27%;
    margin: auto 0;
}
.basiscardlist-content2-data{
    margin: 0 0 70px 0;
}
.basiscardlist-heading h4{
    font-size: 48px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #000000;
    margin: 40px 0;
}
@media screen and (max-width:991px) {
    .basiscardlist-content1,.basiscardlist-content2,.basiscardlist-content3{
        width: 32%;
        margin: auto 0;
    }
    .basiscardlist-content1 p,.basiscardlist-content2 p,.basiscardlist-content3 p{
        font-size: 20px;
    }
    .basiscardlist-heading h4{
        font-size: 36px;
        margin: 20px 0;
    }
}
@media screen and (max-width:767px) {
    .basiscardlist-content1,.basiscardlist-content2,.basiscardlist-content3{
        width: 90%;
        margin: 0px auto 20px;
    }
    .basiscardlist-content2-data{
        margin: 0 0 20px 0;
    }
}